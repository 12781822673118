import { Apartment } from '../interfaces/common.d';

const niittykatu10: Apartment = {
  id: 'nittykatu-10-f',
  previewImageId: 'niittykatu-1.jpeg',
  imageIds: [
    'niittykatu-1.jpeg',
    'niittykatu-2.png',
    'niittykatu-3.jpeg',
    'niittykatu-4.jpeg',
    'niittykatu-5.jpeg',
    'niittykatu-6.jpeg',
    'niittykatu-7.jpeg',
    'niittykatu-8.jpeg',
    'niittykatu-9.jpeg',
    'niittykatu-10.jpeg',
    'niittykatu-11.jpeg',
    'niittykatu-12.jpeg',
    'niittykatu-13.jpeg',
    'niittykatu-14.jpeg',
    'niittykatu-15.jpeg',
    'niittykatu-16.jpeg',
    'niittykatu-17.jpeg',
    'niittykatu-18.jpg',
  ],
  size: '1h+kt - 22',
  address: {
    streetAddress: 'Niittykatu 10',
    neighbourhood: 'Niittykumpu',
    city: 'Espoo',
  },
  rent: 669,
  waterPerPerson: 19,
  availableFrom: '2024-10-01T00:00:00',
  floor: 6,
  description: {
    fi: `
      Moderni vastavalmistunut yksiö hyvällä sijainnilla metroradan varrelta.\b

      Kauppakeskus Niitty sekä Niittykummun metroasema ovat n. 450 m kävelymatkan päässä. Metroasemalta pääsee kätevästi mm. Tapiolaan ja Iso Omenaan (3 min), Aalto Yliopistoon (5 min) ja Helsingin keskustaan (20 min).\b

      Asunto on varustettu moderneilla keittiön kodinkoneilla (mikro/uuni-yhdistelmä, induktioliesi, astianpesukone)
       ja kylpyhuoneessa on liitäntä pyykinpesukoneelle.\b

      Taloyhtiössä on lisäksi oma varastotila, pesutupa sekä sauna.\b

      Vuokralaiselta odotetaan kotivakuutusta vastuuosineen.
    `,
    en: `
    Modern brand new studio in a good location nearby the metro.\b

    Both the shopping mall Niitty and Niittykumpu metro station are a short walk away.
    From the metro station you can quickly reach: Tapiola & Iso Omena (3 min), Aalto University (5 min) and Helsinki center (20 min).\b

    The studio has a modern kitchen with an integrated microwave/oven system, an induction cooktop and a dishwasher. The bathroom has a washing machine extension.\b

    The apartment building has an own storage space, washing machines and a sauna.\b

    We expect a home insurance with liability from all our residents.
  `,
  },
  highlights: {
    condition: 'new',
    hasParking: true,
    hasDishwasher: true,
    hasWashingMachineConnection: true,
    distanceFromMetro: 450,
    distanceFromPark: 200,
    distanceFromMall: 500,
    minutesFromCenter: 25,
  },
  location: [60.1673, 24.7587],
};

const kaivokselantie5: Apartment = {
  id: 'kaivokselantie-5-c-32',
  previewImageId: 'K_living1.jpg',
  imageIds: [
    'K_living1.jpg',
    'K_pohja.jpg',
    'K_living2.jpg',
    'K_kitchen1.jpg',
    'K_kitchen2.jpg',
    'K_kitchen3.jpg',
    'K_kitchen4.jpg',
    'K_bathroom.jpg',
    'K_balcony1.jpg',
    'K_balcony2.jpg',
    'K_balcony3.jpg',
  ],
  size: '1h+kt+p - 31',
  address: {
    streetAddress: 'Kaivokselantie 5 C',
    neighbourhood: 'Kaivoksela',
    city: 'Vantaa',
  },
  rent: 639,
  waterPerPerson: 20,
  availableFrom: '2024-03-01T00:00:00',
  floor: 3,
  description: {
    fi: `
      Moderni yksiö hyvällä sijainnilla. Loistavat kulkuyhteydet mahdollistavat myös monipuoliset mahdollisuudet liikkua.\b

      Kaupat ja bussipysäkki ovat kävelymatkan päässä. Lisäksi monipuoliset palvelut, kauppakeskus Myyrmanni ja Myyrmäen juna-asema ovat 1,6 km päässä.\b

      Asunnossa on lasitettu parveke. Asunto on varustettu moderneilla keittiön kodinkoneilla (mikro/uuni-yhdistelmä, induktioliesi, astianpesukone)
       ja kylpyhuoneessa on liitäntä pyykinpesukoneelle. Eteisestä löytyy kaappitilaa säilytystavaralle.\b

      Taloyhtiössä on lisäksi oma varastotila sekä sauna.\b

      Vuokralaiselta odotetaan kotivakuutusta vastuuosineen. Vesimaksu kulutuksen mukaan, ennakkomaksu 20 €/kk/hlö.
    `,
    en: `
    Modern new studio in a peaceful neighberhood with good public transportation connections.\b

    Shops and the bus station are only a short walk away. Also the services, the shopping mall Myyrmanni & the train station in Myyrmäki are 1.6 km away.\b

    The studio has a glass balcony. The studio has a modern kitchen with an integrated microwave/oven system, an induction cooktop and a dishwasher. The bathroom has a washing machine extension. There is also plenty of storage space in the apartment. \b

    The apartment building has an own storage space and a sauna.\b

    We expect a home insurance with liability from all our residents. Water is charged according to consumption, pre-payment is 20€ / month / person.
  `,
  },
  highlights: {
    condition: 'excellent',
    hasParking: true,
    hasDishwasher: true,
    hasWashingMachineConnection: true,
    distanceFromTrain: 1600,
    distanceFromBus: 20,
    distanceFromPark: 100,
    distanceFromShop: 450,
    minutesFromCenter: 30,
  },
  location: [60.2652814, 24.87041],
};

const berthaPauliginKatu4: Apartment = {
  id: 'bertha-pauligin-katu-4',
  previewImageId: 'aurinkolahti-1.jpg',
  imageIds: [
    'aurinkolahti-1.jpg',
    'aurinkolahti-2.png',
    'aurinkolahti-3.jpg',
    'aurinkolahti-4.jpg',
    'aurinkolahti-5.jpg',
    'aurinkolahti-6.jpg',
    'aurinkolahti-7.png',
  ],
  size: '1h+kt - 25,5',
  address: {
    streetAddress: 'Bertha Pauligin katu 4',
    neighbourhood: 'Aurinkolahti',
    city: 'Helsinki',
  },
  rent: 729,
  waterPerPerson: 20,
  availableFrom: '2024-10-01T00:00:00',
  floor: 1,
  description: {
    fi: `
    Moderni vastavalmistunut yksiö hyvällä sijainnilla metroradan varrelta.\b

    Kauppakeskus Columbus sekä Vuosaaren metroasema ovat n. 300 m kävelymatkan päässä. Metroasemalta pääsee kätevästi mm. Itäkeskukseen (5 min), ja Helsingin keskustaan (20 min).\b

    Asunto on varustettu moderneilla keittiön kodinkoneilla (integroitu mikroaaltouuni, uuni, induktioliesi ja astianpesukone)
     ja kylpyhuoneessa on liitäntä pyykinpesukoneelle.\b

     Asunnon kuvat ovat visualisoituja suunnitelmia, sillä asunto on vielä rakennusvaiheessa.\b

    Taloyhtiössä on lisäksi oma varastotila, pesutupa sekä sauna.\b

    Vuokralaiselta odotetaan kotivakuutusta vastuuosineen. Luottotiedot tarkistetaan.
    `,
    en: `
    Modern brand new studio in a good location nearby the metro and the beach.\b

    Both the shopping mall Columbus and Vuosaari metro station are a short walk away.
    From the metro station you can quickly reach: Itäkeskus (5 min) and Helsinki center (20 min).\b

    The studio has a modern kitchen with an integrated oven, microwave, induction cooktop and dishwasher. The bathroom has a washing machine extension.\b

    The pictures of the studio are visual plans, as the apartment is still under construction. You would be the first resident of this apartment.\b

    The apartment building has an own storage space, washing machines and a sauna.\b

    We expect a home insurance with liability from all our residents. We also check credit information.
    `,
  },
  highlights: {
    condition: 'new',
    hasParking: true,
    hasDishwasher: true,
    hasWashingMachineConnection: true,
    distanceFromMetro: 300,
    distanceFromBeach: 700,
    distanceFromPark: 500,
    distanceFromMall: 300,
    minutesFromCenter: 20,
  },
  location: [60.206, 25.148],
};

// @TODO sort apartments by date
export const APARTMENTS: Apartment[] = [
  niittykatu10
];
export const ALL_APARTMENTS: Apartment[] = [
  niittykatu10,
  berthaPauliginKatu4,
  kaivokselantie5,
];
